<template>
  <div class="content-main-top-container preorder-page">

    <!-- 제목 -->
    <div class="preorder-title-wrap">
      <div class="preorder-title">
        <div class="preorder-bold offset-margin-left preorder-title-mobile"
          style="font-size: 30px;">테마 선주문 문의 신청서</div>
        <div v-if="tab===1" class="preorder-stepper">
          <div style="color: #000; margin-right: 8px;">서비스 구성</div>
          <div style="color: #7f7f7f; margin: 0 8px;">01</div>
          <div style="height: 2px; width: 45px; background: #ff6600;"></div>
          <div style="height: 2px; width: 45px; background: #e6e6e6;"></div>
          <div style="color: #7f7f7f; margin: 0 8px;">02</div>
        </div>
        <div v-if="tab===2" class="preorder-stepper">
          <div style="color: #000; margin-right: 8px;">서비스 확인 및 신청문의</div>
          <div style="color: #7f7f7f; margin: 0 8px;">02</div>
          <div style="height: 2px; width: 90px; background: #ff6600;"></div>
          <div style="color: #7f7f7f; margin: 0 8px;">02</div>
        </div>
      </div>
    </div>

    <!-- 1단계 -->
    <div v-if="tab===1" class="preorder-content-wrap">

      <!-- 신청자 정보 -->
      <div class="preorder-section">
        <div class="preorder-row" style="position: relative;">
          제 이름은
          <div style="position: relative;">
            <input class="input-name" type="text"
              :value="value.name"
              @input="blurInput">
              <!-- @input="(e) => value.name = e.target.value"> -->
              <!-- @keyup.enter="blurInput" -->
              <!-- @blur="blurInput"> -->

            <!-- validation 말풍선 -->
            <!-- <div v-if="showSpeechBubble"
              class="validation-speech-bubble">이름은 한글로 10자 이하로 입력해주세요.</div> -->
          </div>
          인데요.
          <img class="rocket-image" src="../../assets/img/preorder/rocket.png">
        </div>
        <div class="preorder-row">
          제가 만들고 싶은 플랫폼 서비스가 있어요.
        </div>
      </div>

      <!-- 서비스 요약 (Service Summary) -->
      <transition name="show-section">
      <div v-if="showServiceSummary"
        class="preorder-section">
        <div class="preorder-row">
          <div class="mobile-margin-right">저는 제 서비스를 방문한 사용자가</div>
          <!-- 플랫폼 타입 -->
          <dropdown
            label_name="text"
            value_name="id"
            :value.sync="value.platform"
            :options="option.platform"
            :width="296"
            :mobileStyle="{ width: '180px' }"
            ></dropdown>
          <!-- 팩 종류 -->
          <dropdown
            label_name="text"
            value_name="id"
            :value.sync="value.order"
            :options="option.order"
            :width="356"
            ></dropdown>
          을(를) 하면
        </div>
        <div class="preorder-row">
          <div class="preorder-bold preorder-border offset-margin-left selected-platform">
            해당 {{ selectedPlatform }}
          </div>
          <div class="mobile-margin-right">가</div>
          <div class="preorder-bold preorder-border selected-order">
            {{ selectedOrder }}
          </div>
        </div>
        <div class="preorder-row">
          하는 서비스를 만들고 싶어요.
        </div>
      </div>
      </transition>

      <!-- 서비스 상세기능 -->
      <transition name="show-section">
      <div v-if="showServiceDetail"
        class="preorder-section">
        <div class="preorder-row">
          <div class="mobile-full-width">사용자들이 주문하기전에</div>
          <div class="preorder-bold">
            {{ selectedPlatform }}
          </div>
          <div>에게 문의를 할 수 있게</div>
        </div>
        <div class="preorder-row">
          <div class="mobile-margin-right">상품 상세페이지에</div>
          <!-- 문의전환 종류 -->
          <dropdown
            label_name="name"
            value_name="id"
            :value.sync="value.inquiry"
            :options="option.inquiry"
            :width="208"
            :mobileStyle="{ width: '160px' }"
            ></dropdown>
          <div>가 있었으면 좋겠어요.</div>
        </div>
        <div class="preorder-row">
          <div class="mobile-margin-right">제 서비스에서 결제는</div>
          <!-- 결제 타입 -->
          <dropdown
            label_name="name"
            value_name="id"
            :value.sync="value.payment"
            :options="option.payment"
            :width="186"
            :mobileStyle="{ width: '80px' }"
            ></dropdown>
          <div>거예요.</div>
        </div>
        <div class="preorder-row">
          <div class="mobile-margin-right">그밖의 기능으로 회원등급이</div>
          <!-- 회원 등급 -->
          <dropdown
            label_name="name"
            value_name="id"
            :value.sync="value.userGrade"
            :options="option.userGrade"
            :width="108"
            :mobileStyle="{ width: '80px' }"
            ></dropdown>
          <div>해요</div>
        </div>
        <div class="preorder-row">
          <div class="mobile-margin-right">그리고 서비스를 제공하는 사람은 등급이</div>
          <!-- 파트너 등급 -->
          <dropdown
            label_name="name"
            value_name="id"
            :value.sync="value.partnerGrade"
            :options="option.partnerGrade"
            :width="130"
            :mobileStyle="{ width: '100px' }"
            ></dropdown>
          <div>좋겠어요.</div>
        </div>
      </div>
      </transition>

      <!-- 디자인 -->
      <transition name="show-section">
      <div v-if="showServiceDesign"
        class="preorder-section">
        <div class="preorder-row">
          제가 생각하는 페이지 디자인은
        </div>
        <div class="preorder-row row-extend-margin">
          메인 페이지는
          <file-uploader fileType="image" type="main" placeholder="참조할 앱 스크린샷 첨부"
                         :file="value.main_upload[0]"
                         :showImage="true"
                         @uploadedFile="(file) => getFile(file, 'main', 'image')"></file-uploader>
          이렇게, 자세한 설명은
          <file-uploader fileType="ppt" type="main" placeholder="PPT 파일 첨부"
                         :file="value.main_upload[1]"
                         @uploadedFile="(file) => getFile(file, 'main', 'ppt')"></file-uploader>
          여기를 봐주세요.
        </div>
        <div class="preorder-row row-extend-margin">
          상품목록 페이지는
          <file-uploader fileType="image" type="product" placeholder="참조할 앱 스크린샷 첨부"
                         :file="value.product_upload[0]"
                         :showImage="true"
                         @uploadedFile="(file) => getFile(file, 'product', 'image')"></file-uploader>
          이렇게, 자세한 설명은
          <file-uploader fileType="ppt" type="product" placeholder="PPT 파일 첨부"
                         :file="value.product_upload[1]"
                         @uploadedFile="(file) => getFile(file, 'product', 'ppt')"></file-uploader>
          여기를 봐주세요.
        </div>
        <div class="preorder-row row-extend-margin">
          그리고 상품상세 페이지는
          <file-uploader fileType="image" type="product_detail" placeholder="참조할 앱 스크린샷 첨부"
                         :file="value.product_detail_upload[0]"
                         :showImage="true"
                         @uploadedFile="(file) => getFile(file, 'product_detail', 'image')"></file-uploader>
          이렇게, 자세한 설명은
          <file-uploader fileType="ppt" type="product_detail" placeholder="PPT 파일 첨부"
                         :file="value.product_detail_upload[1]"
                         @uploadedFile="(file) => getFile(file, 'product_detail', 'ppt')"></file-uploader>
          여기를 봐주세요.
        </div>
        <div class="preorder-row">
          <input class="input-name" type="text" v-model="value.blank_page">
          페이지는
          <file-uploader fileType="image" type="blank" placeholder="참조할 앱 스크린샷 첨부"
                         :file="value.blank_upload[0]"
                         :showImage="true"
                         @uploadedFile="(file) => getFile(file, 'blank', 'image')"></file-uploader>
          이렇게, 자세한 설명은
          <file-uploader fileType="ppt" type="blank" placeholder="PPT 파일 첨부"
                         :file="value.blank_upload[1]"
                         @uploadedFile="(file) => getFile(file, 'blank', 'ppt')"></file-uploader>
          여기를 봐주세요.
        </div>
      </div>
      </transition>

      <!-- 템플릿 양식 안내 -->
      <!-- <div class="template-area">
        <i class="material-icons info-icon">info</i>
        <div>
          <div>
            맞춤디자인을 원치 않을 경우 런치팩에서 제공하는 <span class="template-highlight">기본템플릿</span> 디자인으로 대체됩니다 <br>
            페이지디자인은 아래 양식을 사전에 다운로드하여 양식에 맞춰 내용을 수정 후 개별로 업로드할 수 있습니다
          </div>
          <div class="lp-template-list">
            <div class="lp-template-item template-highlight">메인페이지 양식</div>
            <div class="lp-template-item template-highlight">상품상세페이지 양식</div>
            <div class="lp-template-item template-highlight">상품목록페이지 양식</div>
            <div class="lp-template-item template-highlight">사용자임의 추가페이지 양식</div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- 2단계 -->
    <div v-if="tab===2" class="preorder-content-wrap">
      <preorder2
        ref="preorderNextpage"
        :value="value"
        :option="option"></preorder2>
    </div>

    <!-- 버튼 -->
    <transition name="show-section">
    <div class="next-page-btn-wrap" v-if="tab===1 && showServiceDesign">
      <div class="next-page-btn" @click="clickButton(2)">이런 내 서비스 창업 하려면 얼마나 들까요?</div>
    </div>
    </transition>

    <!-- 버튼 -->
    <div class="next-page-btn-wrap" v-if="tab===2"
       style="margin-top: 0;">
      <!-- :style="`backgroundImage: url(${require('../../assets/img/preorder/rocket_background.png')});`"> -->
      <div>
        <img src="../../assets/img/preorder/rocket_background.png">
      </div>
      <div class="next-page-btn-2" @click="$refs.preorderNextpage.showModal=true;"
        style="position: absolute;">이런 내 서비스 창업 하려면 얼마나 들까요?</div>
      <div class="next-page-btn-tip">* 누르시면 상품의 기본적인 견적이 나옵니다.<br>
        바로 신청되지는 않으니 걱정 안하셔도 됩니다.</div>
    </div>

  </div>
</template>

<script>
import Dropdown from '../component/Dropdown.vue'
import FileUploader from '../component/FileUploader.vue'
import Preorder2 from "./Preorder2";


export default {

  components: {
    Preorder2,
    Dropdown,
    FileUploader
  },

  data() {
    return {
      tab: 1,
      /*각 드롭다운에 들어가는 옵션 데이터 요약
        select: 추후에 서비스 신청할시 string 데이터를 변환시켜서 디비에 저장하기 위해 있는 데이터필드
        name: 상품이름
        text: 대체문구
      */
      // 신청 입력/선택 값
      params: {
        main_upload: [],
        product_upload: [],
        product_detail_upload: [],
        blank_upload: [],
        blank_page: ""
      },

      // 참조할 앱 스크린샷 첨부파일 디폴트값
      appImage: {
        /*main: require('../../assets/img/preorder/design/board.png'),
        product: require('../../assets/img/preorder/design/board_detail.png'),
        product_detail: require('../../assets/img/preorder/design/exhibition.png'),
        blank: require('../../assets/img/preorder/design/exhibition.png'),*/
        main: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/default/home.png',
        product: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/default/product.png',
        product_detail: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/default/productdetail.png',
        blank: ''
      },

      value: {
        type: 1,
        products: [],                 // 선택된 상품들
        name: '',                     // 신청자 이름
        platform: 10000,              // 플랫폼 사업 타입
        order: 10000,                 // 주문전환 종류
        inquiry: 10000,               // 문의전환 종류
        payment: 10000,               // 결제 종류
        userGrade: 10000,             // 회원등급 여부
        partnerGrade: 10000,          // 파트너등급 여부

        main_upload: [],
        product_upload: [],
        product_detail_upload: [],
        blank_upload: [],
        blank_page: "",

        order_conversion_type: 0,
        inquiry_conversion_type: 0,
        payment_type: 0,
        plugins: [],
        package: ''

        /* design: {            // 페이지 디자인 첨부파일
          main: {               // 메인
            file: '',
            name: ''
          },
          productList: {        // 상품목록
            file: '',
            name: ''
          },
          productDetail: {      // 상품상세
            file: '',
            name: ''
          },
          user: {               // 사용자임의 추가페이지
            pageName: '',
            file: '',
            name: ''
          }
        } */
      },

      showSpeechBubble: false,    // 말풍선 보여주기

      showServiceSummary: false,    // 서비스요약 섹션 보여주기 (두번째 섹션)
      showServiceDetail: false,     // 서비스상세 섹션 보여주기 (세번째 섹션)
      showServiceDesign: false,     // 서비스디자인 섹션 보여주기 (네번째 섹션)

      // 사용자 임의 추가페이지 이름
      option: {
        // 플랫폼 타입
        platform: [
          {
            id: 0,
            text: '사용자의 상품을 보고',
            name: '사용자'
          },
          {
            id: 1,
            text: '업체의 상품을 보고',
            name: '업체'
          },
          {
            id: 2,
            text: '관리자에게',
            name: '관리자'
          }
        ],

        // 기본 가격
        basic: [],
        // 팩/주문전환 종류
        order: [
          {
            id: 0,
            text: '용역서비스나 특별컨텐츠를 주문하면',
            conversion: '약속한 일을 하거나 결과물을 업로드'
          },
          {
            id: 1,
            text: '상담신청',
            conversion: '전화 상담'
          },
          {
            id: 2,
            text: '모임신청',
            conversion: '신청 내역을 확인, 승인'
          },
          {
            id: 3,
            text: '클릭해서 해당 쇼핑몰로 이동',
            conversion: '아웃링크 된 내역을 확인'
          },
          {
            id: 4,
            text: '상품의 옵션에 따른 견적을 요청',
            conversion: '견적서를 전송'
          },
          {
            id: 5,
            text: '업체들의 입찰견적을 요청',
            conversion: '맞는 업체들에게 견적 요청을 보내고 업체나 전문가가 사용자에게 견적서를 전송'
          }
        ],

        // 문의전환 종류
        inquiry: [
          {
            id: 0,
            name: '이메일문의'
          },
          {
            id: 1,
            name: '문자문의'
          },
          {
            id: 2,
            name: '판매자 톡 문의'
          },
          {
            id: 3,
            name: '관리자 톡 문의'
          },
          {
            id: 4,
            name: '상품 게시판 문의'
          }
        ],

        // 결제 타입
        payment: [
          {
            id: 0,
            name: '안할'
          },
          {
            id: 1,
            name: '주문시 할'
          },
          {
            id: 2,
            name: '배달 시 할'
          },
          {
            id: 3,
            name: '구매확정 시 할'
          },
        ],

        // 회원 등급
        userGrade: [
          {
            id: 1,
            name: '필요',
          },
          {
            id: 0,
            name: '안필요'
          },
        ],

        // 파트너 등급
        partnerGrade: [
          {
            id: 1,
            name: '있었으면'
          },
          {
            id: 0,
            name: '없었으면'
          },
        ],
        plugIn: [],
      },

      // 로그인 정보
      user: {}
    }
  },

  computed: {
    // 선택된 플랫폼 주체 타입
    selectedPlatform() {
      let name = '';
      for(let i=0; i<this.option.platform.length; i++) {
        if (this.option.platform[i].id === this.value.platform) {
          name = this.option.platform[i].name;
        }
      }
      return name;
    },

    // 선택된 주문전환 타입
    selectedOrder() {
      let name = '';
      for(let i=0; i<this.option.order.length; i++) {
        if (this.option.order[i].id === this.value.order) {
          name = this.option.order[i].conversion;
        }
      }
      return name;
    }
  },

  created() {
    // 로그인 시 회원정보
    if(this.$store.getters.user.name) {
      let user = this.cloneItem(this.$store.getters.user)
      let user_id = '';
      if (user.user_id > 0) user_id = user.user_id;
      this.user.user_id = user_id;
      this.user.name = user.name;
      this.user.email = user.email;
      this.user.phone = user.phone;
      setTimeout(() => {
        this.showServiceSummary = true;
      },1000);
    }

    this.value.name = this.user.name;

    // 이미지 필드 초기화
    ['main', 'product', 'product_detail', 'blank'].forEach(item => {
      let page_name = '';
      switch(item) {
        case 'main': page_name = '메인'; break;
        case 'product': page_name = '상품목록'; break;
        case 'product_detail': page_name = '상품상세'; break;
        case 'blank': page_name = '추가'; break;
      }
      this.value[item+'_upload'].push({ file: this.appImage[item], name: page_name+' 페이지'});
      this.value[item+'_upload'].push({ file: '', name: ''});
      // for(let i=0;i<2; i++){
        // this.value[item+'_upload'].push({ file: this.appImage[item], name: item+' 페이지 디폴트'});
      // }
    });
    this.getEstimateForm();
  },

  watch: {
    'value.platform'() {
      this.selectSummary();
    },
    'value.order'() {
      this.selectSummary();
    },

    'value.inquiry'() {
      this.selectDetail();
    },

    'value.payment'() {
      this.selectDetail();
    },

    'value.userGrade'() {
      this.selectDetail();
    },

    'value.partnerGrade'() {
      this.selectDetail();
    },

  },

  methods: {
    clickButton(tab) {
      this.tab = tab;
      // 스텝 2
      if(tab===2) {
        // 다음 페이지 가기 전 선택한 상품들 추가
        if(this.value.order > 0) this.value.products.push(this.value.order);
        if(this.value.payment > 0) this.value.products.push(this.value.payment);
        if(this.value.platform > 0) this.value.products.push(this.value.platform);
        if(this.value.inquiry > 0) this.value.products.push(this.value.inquiry);

        this.checkProducts();

        /*
        플러그인은 예외 처리
        0: 안 산다
        1: 산다
        * */
        if(this.value.userGrade > 0) {
          this.option.plugIn.forEach(item=>{
            if(item.name==='회원등급') this.value.products.push(item.id);
          })
        }
        if(this.value.partnerGrade > 0) {
          this.option.plugIn.forEach(item=>{
            if(item.name==='파트너등급') this.value.products.push(item.id);
          })
        }

        // window.scrollTo(0,0);
      }
      else if(tab===3) {
        this.modalOpen();
      }
    },

    modalOpen(){
      this.$refs.modal.open();
    },

    // 견적 양식 데이터 가져오기
    getEstimateForm() {
      this.$axios.get('public/launchpack/estimate/form').then(res=>{
        let cats = res.data;
        //console.log(cats)
        cats.forEach(cat=>{
          if(cat.name==='기본'){
            this.option.basic = cat.products;
          }
          else if(cat.name==='플랫폼'){
            this.option.platform = cat.products;
          }
          else if(cat.name==='주문전환'){
            this.option.order = cat.products;
            cat.products.forEach((prod) => {
              switch(prod.name) {
                case '견적요청':
                  prod.conversion = '맞는 업체들에게 견적 요청을 보내고 업체나 전문가가 사용자에게 견적서를 전송';
                  break;
                case '모임신청':
                  prod.conversion = '신청 내역을 확인, 승인';
                  break;
                case '상담신청':
                  prod.conversion = '전화 상담';
                  break;
                case '컨텐츠다운로드':
                  prod.conversion = '약속한 일을 하거나 결과물을 업로드';
                  break;
                case '아웃링크':
                  prod.conversion = '아웃링크 된 내역을 확인';
                  break;
                case '전화연결':
                  prod.conversion = '전화연결';
                  break;
                /* case '상품옵션견적':
                  prod.conversion = '상품의 옵션에 따른 견적을 요청';
                  break; */
              }
            });
          }
          else if(cat.name==='문의전환'){
            this.option.inquiry = cat.products;
          }
          else if(cat.name==='결제방식'){
            this.option.payment = [{id:0, name:'안할', text:'안할'}];

            let target = this.findItem(cat.products, 'name', '결제이용');
            target.name = '이용할';
            target.text = '이용할';

            this.option.payment = this.option.payment.concat(cat.products);
          }
          // 플러그인은 예외 케이스이기 때문에 별도로 저장
          else if(cat.name==='플러그인'){
            this.option.plugIn = cat.products;
          }
        })
      })
    },

    saveData() {
    },

    getFile(file, type, fileType) {
      let idx = fileType === 'ppt' ? 1 : 0;
      this.value[type+'_upload'][idx] = file;
    },

    blurInput(e) {
      this.value.name = e.target.value;

      if (this.validateName(this.value.name)) {
        this.showServiceSummary = true;
        // this.showSpeechBubble = false;
      }
      else {
        this.showServiceSummary = false;
        // this.showSpeechBubble = true;
      }
    },

    // 이름 유효성 검사
    validateName(str) {
      let pattern_kor = /[가-힣]/; // 한글체크
      return ((pattern_kor.test(str)) && str.length>0 && str.length<=10);
    },

    // 서비스 요약 섹션 값 체크
    selectSummary() {
      this.showServiceDetail = ( this.value.platform!==10000 && this.value.order!==10000 );
    },

    // 서비스 상세 섹션 값 체크
    selectDetail() {
      if ( this.value.inquiry!==10000 && this.value.payment!==10000 &&
        this.value.userGrade!==10000 && this.value.partnerGrade!==10000 ) {
        this.showServiceDesign = true;
      }
      else {
        this.showServiceDesign = false;
      }
    },
    convertOrder(name) {
      switch (name) {
        case '없음': return 0;
        case '전화연결': return 1;
        case '아웃링크': return 2;
        case '상품배송주문': return 3;
        case '모임신청': return 4;
        case '상담신청': return 5;
        case '견적요청': return 6;
        case '사용권': return 7;
        case '컨텐츠다운로드주문': return 8;
      }
    },

    convertInquiry(name) {
      switch (name) {
        case '없음': return 0;
        case '전화문의': return 1;
        case '이메일문의': return 2;
        case '톡문의': return 3;
      }
    },

    convertPayment(name) {
      switch (name) {
        case '없음': return 0;
        case '결제이용': return 1;
      }
    },

    convertPack(number) {
      // 주문 전환에 맞춰서 패키지이름 매칭을 시켜야함.
      switch (number) {
        case 1: return 'call';
        case 2: return 'outlink';
        case 3: return '';
        case 4: return 'meet';
        case 5: return 'counsel';
        case 6: return 'estimate';
        case 7: return '';
        case 8: return 'download';
      }
    },

    checkProducts() {
      // 주문전환
      if (this.value.order > 0) {
        this.option.order.forEach(order=>{
          if(order.id === this.value.order){
            this.value.order_conversion_type = this.convertOrder(order.name);
            this.value.package = this.convertPack(this.value.order_conversion_type);
          }
        })
      }
      // 문의전환
      if (this.value.inquiry > 0) {
        this.option.inquiry.forEach(inquiry=>{
          if(inquiry.id === this.value.inquiry) {
            this.value.inquiry_conversion_type = this.convertInquiry(inquiry.name);
          }
        })
      }
      // 결제방식
      if(this.value.payment) {
        this.option.payment.forEach(payment=>{
          if(payment.id === this.value.payment) {
            this.value.payment_type = this.convertPayment(payment.name);
          }
        })
      }
      // 플러그인
      if(this.value.userGrade > 0) {
        this.value.plugins.push('user_grade');
      }
      if(this.value.partnerGrade > 0) {
        this.value.plugins.push('partner_grade');
      }
    },

  }

}
</script>

<style scoped>

  @media (max-width: 600px) {
    .row-extend-margin {
      margin-bottom: 60px !important;
    }
    .preorder-title-wrap {
      padding: 0 20px;
      margin-bottom: 32px;
    }
    .preorder-title {
      width: 100%;
    }
    .preorder-title-mobile {
      margin-bottom: 8px;
    }
    .preorder-content-wrap {
      width: 100%;
      padding: 0 20px;
    }
    .preorder-section {
      margin-bottom: 40px;
    }
    .preorder-page {
      font-size: 16px;
      line-height: 32px;
    }
    .input-name {
      font-size: 16px;
      padding: 4px 8px;
      width: 120px;
      height: 32px;
    }
    .preorder-row {
      margin-bottom: 4px;
      line-height: 32px;
      display: inline-block;
    }
    /* .preorder-border {
      height: 32px;
    } */
    .preorder-bold {
      font-weight: 700;
      margin: 0;
      display: inline-block;
      /* margin: 0 4px; */
    }
    .mobile-full-width {
      width: 100%;
    }
    .mobile-margin-right {
      margin-right: 8px;
    }
    .next-page-btn {
      width: 88%;
    }
    .next-page-btn-2 {
      position: absolute;
      width: 88%;
      margin: 0;
      margin-top: 74px;
    }
  }
  @media (min-width: 601px) {
    .preorder-title-wrap {
      margin-bottom: 80px;
    }
    .preorder-title {
      width: 72%;
    }
    .preorder-content-wrap {
      width: 72%;
    }
    .preorder-section {
      margin-bottom: 140px;
    }
    .preorder-page {
      font-size: 24px;
      line-height: 40px;
    }
    .input-name {
      font-size: 24px;
      padding: 8px 16px;
      width: 180px;
      height: 40px;
    }
    .preorder-row {
      margin-bottom: 20px;
    }
    .preorder-border {
      border-bottom: 2px solid #303030;
      height: 42px;
    }
    .selected-platform {
      min-width: 44px;
    }
    .selected-order {
      min-width: 360px;
    }
    .preorder-bold {
      font-weight: 700;
      margin: 0 12px;
    }
    .next-page-btn {
      width: 400px;
    }
    .next-page-btn-2 {
      position: absolute;
      width: 400px;
      margin: 0;
      /* margin-top: 74px; */
    }
  }
  @media screen and (min-width: 1280px) {
  }

  .preorder-page {
    font-weight: 200;
    color: #303030;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .preorder-title-wrap {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .preorder-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .preorder-subtitle {
    margin-bottom: 16px;
    font-weight: bold;
  }

  .preorder-stepper {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
  }

  .preorder-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .input-name {
    border: none;
    border-bottom: 2px solid #303030;
    /* width: 180px; */
    /* height: 40px; */
    box-shadow: 0;
    /* padding: 8px 16px; */
    /* font-size: 24px; */
    margin: 0 12px;
  }

  .rocket-image {
    width: 116px;
    position: absolute;
    left: 500px;
  }

  .validation-speech-bubble {
    position: absolute;
    font-size: 12px;
    color: #fff;
    background: #303030;
    padding: 10px;
    line-height: 18px;
    border-radius: 10px;
    width: 184px;
    bottom: 44px;
    font-weight: 500;
    left: 8px;
  }

  .validation-speech-bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #303030;
    border-bottom: 0;
    margin-left: 52px;
    margin-bottom: -6px;
  }

/* .preorder-bold {
    font-weight: 700;
    margin: 0 12px;
  } */

  .preorder-bold.offset-margin-left {
    margin-left: 0;
  }

  .template-area {
    display: flex;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 30px 20px;
    font-size: 16px;
    color: #c7c7c7;
    line-height: 40px;
    font-weight: 500;
  }

  .material-icons.info-icon {
    line-height: 40px;
    margin-right: 6px;
  }

  .lp-template-list {
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .lp-template-item {
    width: 240px;
    cursor: pointer;
  }

  .template-highlight {
    text-decoration: underline;
    font-weight: 700;
    color: #7ad4ef;
  }

  .next-page-btn-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .next-page-btn {
    height: 64px;
    margin: 24px;
    background: #ff6600;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .next-page-btn-2 {
    height: 64px;
    background: #ff6600;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .show-section-enter-active, .show-section-leave-active {
    transition: opacity 2s;
  }
  .show-section-enter, .show-section-leave-to {
    opacity: 0;
  }

  .next-page-btn-tip {
    font-size: 12px;
    position: absolute;
    top: 70px;
    font-weight: 400;
    line-height: 20px;
    color: #777;
  }



</style>
